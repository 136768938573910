import Component from 'gia/Component'
import eventbus from 'gia/eventbus';
import stage from '../components/stage'
import renderer from '../components/render'

import _each from 'lodash.foreach'
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);
import Sequencer from './sequencer'

import Observer from '../components/observer'



class Video extends Component {

  constructor(element) {

    super(element)

    this.body = $('body')
    this.html = $('html')
    this.menu = $('.menu')
    this.element = element
    this.el = $(element)
    this.i = 0


    this.ref = {
      title: null,
      cover: null,
      canvas: [],
      canvasm: [],
      el: [],
      elm: [],
      proxy: null,
      nav: null, 
      bar: null,
      sequencer: [],
      sequence: [],
      bordered: [], 
      start: [],
      end: [],
      overimage: null,
      profile: null,
      pstart: null, 
      pend: null,
      profiletext: null,
      oimage: null, 
      ostart: null,
      oend: null,
      otext: null,
      img: [],
      wrap: []
    }

  }

  mount() {
    this.items = []
    this.queue =  this.queueComplete.bind(this)


    gsap.to(this.ref.title, {
      yPercent: -100, 
      scale:.6, 
      opacity: 0,
      scrollTrigger: {
        trigger: this.ref.cover,
        start: "top top",
        end: "bottom top+=20%",
        scrub: true
      }
    })
  
    this.ref.sequencer.forEach((item, index)=>{
        
        const first = item.dataset.first
        const last = item.dataset.last
        const start = item.dataset.start || 'bottom'

        const complete = () => {
            this.ref.el[index].classList.add('complete')
        }

        const s2 = Sequencer.make({
            canvas: this.ref.canvas[index],
            from: first,
            to: last,
            scaleMode: "cover",
            playMode: "scroll",
            el: this.ref.el[index],
          //  proxy: this.ref.el[index],
            // imageLoad : this.imageLoad.bind(this), 
            queueComplete: complete

        })

        

        ScrollTrigger.create({
            trigger: this.ref.el[index],
            start: `${start} bottom`,
            end: 'bottom top',
            onUpdate: (self) => {
                s2.scr(self);
            }
        });

      
        this.items.push(s2)


    })




   


     this.ref.bordered.forEach((item, i) => {


           const tl = gsap.timeline({
                  scrollTrigger: {
                      trigger: item,
                      start: "top bottom",
                      end: "bottom top",
                      scrub: .2,
                      toggleActions: "play reverse play reverse"
                  }
              });

              tl.to(this.ref.start[i], {
                  xPercent: -100,
                  duration: 0.2
              }, 0.2)
              tl.to(this.ref.end[i], {
                  xPercent: 100,
                  duration: 0.2
              }, 0.2)

              tl.to(this.ref.start[i], {
                xPercent: 0,
                duration: 0.25
              }, 0.75)
              tl.to(this.ref.end[i], {
                xPercent: 0,
                duration: 0.25
              }, 0.75)
             

     })

     gsap.set(this.ref.profile, {
          scale: 1.425,
          xPercent: -50,
          yPercent: 80,
     })
     gsap.set(this.ref.pstart, {
       xPercent: -100,
     })
     gsap.set(this.ref.pend, {
       xPercent: 100,
     })

     const tp = gsap.timeline({
       scrollTrigger: {
         trigger: this.ref.overimage,
         start: "top bottom",
         end: "top top",
         scrub: .2,
         toggleActions: "play reverse play reverse"
       }
     });

     tp.to(this.ref.profile,{
       scale: 1,
       yPercent: 0,
       xPercent: 0,
       duration: 0.3
     }, 0.15)

     tp.to(this.ref.pstart, {
       xPercent: 0,
       duration: 0.4
     }, 0.15)
     tp.to(this.ref.pend, {
       xPercent: 0,
       duration: 0.4
     }, 0.15)

     tp.to(this.ref.profiletext,{
       xPercent: -120,
       duration: 0.2
     }, 0.15)


      // const tb = gsap.timeline({
      //   scrollTrigger: {
      //     trigger: this.ref.oimage,
      //     start: "top bottom",
      //     end: "top top",
      //     scrub: .2,
      //     toggleActions: "play reverse play reverse"
      //   }
      // });

     
      // tb.fromTo(this.ref.ostart, {
      //   xPercent: -100,
      // }, {
      //   xPercent: 0,
      //   duration: 0.4
      // }, 0.15)
      // tb.fromTo(this.ref.oend, {
      //   xPercent: 100,
      // }, {
      //   xPercent: 0,
      //   duration: 0.4
      // }, 0.15)
      //  tb.to(this.ref.otext, {
      //    xPercent: -120,
      //    duration: 0.2
      //  }, 0.15)

 this.ref.sequence.forEach((item, index) => {

   const first1 = item.dataset.first
   const last1 = item.dataset.last
   const start1 = item.dataset.start || 'bottom'



   const complete = () => {
     this.ref.elm[index].classList.add('complete')

   }

   const s3 = Sequencer.make({
     canvas: this.ref.canvasm[index],
     from: first1,
     to: last1,
     scaleMode: "cover",
     playMode: "scroll",
     el: this.ref.elm[index],
     //  proxy: this.ref.el[index],
     // imageLoad : this.imageLoad.bind(this), 
     queueComplete: complete

   })



   ScrollTrigger.create({
     trigger: this.ref.wrap[index],
     start: "top top-=100%",
      end: "top top-=120%",
     onUpdate: (self) => {
       s3.scr(self);
     }
   });


   // this.items.push(s2)

    const seq = gsap.timeline({
      scrollTrigger: {
        trigger: this.ref.wrap[index],
        start: "top top",
        end: "top top-=100%",
        scrub: .2,
        toggleActions: "play reverse play reverse"
      }
    });

    seq.to(this.ref.img[index], {
      scale: .5,
      transformOrigin: 'center 80%',
      duration: .3
    }, .5)

    seq.to(this.ref.img[index], {
      opacity: 0,
      duration: 0.1
    }, 0.8)

    seq.fromTo(this.ref.elm[index], {
      opacity: 0
    }, {
      opacity: 1,
      duration: 0.1
    }, 0.8)


 })


      


        const invobserver = new Observer({
          rootMargin: '-30% 0% -30% 0%'
        });

        invobserver.add('.vprofile-text__item');

        const tnvobserver = new Observer({
          rootMargin: '-10% 0% -10% 0%'
        });

        tnvobserver.add('.vprofile-text__title');


      this.resizeQue = this.resize.bind(this)
      stage.on("stage:resize", this.resizeQue);
      this.resize()

  }

  imageLoad(e){
    

    gsap.set(this.ref.bar, {
      transformOrigin: 'left',
      scaleX: e.count/e.total
    })
  }

   queueComplete(seq) {
     //console.log(seq);
   }


  resize(){

    this.items.forEach((item, index)=>{
           item.size(this.ref.el[index].clientWidth, this.ref.el[index].clientHeight);
    })

    this.ref.sequence.forEach((item, index)=>{
        const box = item.getBoundingClientRect()
        const offset = (stage.h - box.height)
        item.style.setProperty('--bottom-offset', offset + "px");
    })


    
 
  }




  unmount() {




  }


}

module.exports = Video
