import Component from 'gia/Component'
import _each from 'lodash.foreach'
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);


class Clip extends Component {

  constructor(element) {

    super(element)

    this.body = $('body')
    this.html = $('html')
    this.menu = $('.menu')
    this.element = element
    this.el = $(element)
    this.index = 0


    this.ref = {
      clip: null,
      switch: null 
    }

  }

  mount() {


    this.ref.switch.addEventListener("click",  (e) =>{
        this.element.classList.toggle('visible')
    });
   

    
}






  unmount() {




  }


}

module.exports = Clip
