
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);

import loadComponents from 'gia/loadComponents';

import Menu from './menu'
import Clip from './clip'
import Form from './form'
import Cover from './cover'
import Animation from './animation'
import Slider from './slider'
import Video from './video'
import Vid from './vid'
import Popup from './popup'
import Discover from './discover'
import Story from './story'
import Page from './page'
import Continents from './continents'
import Timeline from './timeline'
import Locations from './locations'
import Contact from './contact'
import Partner from './partner'


class App {

  constructor($) {
    this.create()
  }

  create() {

    //  lazySizes.init()

      const components = {
        Animation: Animation,
        Partner: Partner,
        Slider: Slider,
        Discover: Discover,
        Clip: Clip,
        Cover: Cover,
        Story: Story, 
        Page: Page,
        Menu: Menu,
        Video: Video,
        Continents: Continents,
        Timeline: Timeline,
        Locations: Locations,
        Contact: Contact,
        Form: Form,
        Vid: Vid,
        Popup: Popup
       
      }

      loadComponents(components)

      $('body').addClass('loaded')

       $('#menuBtn').on('click', (e) => {
         e.preventDefault()
         $('body').toggleClass('has-menu')
       })

       $('.menu a').on('click', (e) => {

         $('body').removeClass('has-menu')
       })

  }
}

module.exports = App
