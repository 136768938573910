import Component from 'gia/Component'
import eventbus from 'gia/eventbus';
import stage from '../components/stage'
import renderer from '../components/render'
import _each from 'lodash.foreach'
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);

import Swiper from 'swiper'



class Slider extends Component {

  constructor(element) {

    super(element)

    this.body = $('body')
    this.html = $('html')
    this.menu = $('.menu')
    this.element = element
    this.el = $(element)
    this.index = 0


    this.ref = {
      slider: null
    }

  }

  mount() {

      this.swiper = new Swiper(this.ref.slider, {
        slidesPerView: 1,
        loop: true,
        allowTouchMove: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }
        
      });
    
}






  unmount() {




  }


}

module.exports = Slider
