'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _config = require('./config');

var _config2 = _interopRequireDefault(_config);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Event bus for storing and executing handlers on emitted events
 */

var EventBus = function () {
    function EventBus() {
        _classCallCheck(this, EventBus);

        this.list = {};
    }

    _createClass(EventBus, [{
        key: 'emit',
        value: function emit(event) {
            var _this = this;

            var eventObject = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            eventObject._name = event;
            if (this.list[event]) {
                if (_config2.default.get('log')) {
                    console.info(this.list[event].length + ' handler' + (this.list[event].length > 1 ? "s" : "") + ' called on event \'' + event + '\'');
                }
                this.list[event].forEach(function (handlerObject) {
                    handlerObject.handler(eventObject);
                    if (handlerObject.once) {
                        _this.off(event, handlerObject.handler);
                    }
                });
            } else {
                if (_config2.default.get('log')) {
                    console.info('0 handlers called on event \'' + event + '\'');
                }
            }
        }
    }, {
        key: 'on',
        value: function on(event, handler) {
            var once = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

            if (this.list[event]) {
                this.list[event].push({ once: once, handler: handler });
            } else {
                this.list[event] = [];
                this.list[event].push({ once: once, handler: handler });
            }
        }
    }, {
        key: 'once',
        value: function once(event, handler) {
            this.on(event, handler, true);
        }
    }, {
        key: 'off',
        value: function off(event, handler) {
            if (event != null) {
                if (handler != null) {
                    if (this.list[event] && this.list[event].filter(function (eventObject) {
                        return eventObject.handler === handler;
                    }).length) {
                        var toRemove = this.list[event].filter(function (eventObject) {
                            return eventObject.handler === handler;
                        })[0];
                        var index = this.list[event].indexOf(toRemove);
                        if (index > -1) {
                            this.list[event].splice(index, 1);
                        }
                    } else {
                        console.warn('Event ' + event + ' cannot be unsubscribed - does not exist.');
                    }
                } else {
                    this.list[event] = [];
                }
            } else {
                this.list = {};
            }
        }
    }]);

    return EventBus;
}();

exports.default = new EventBus();
