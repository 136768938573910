import Component from 'gia/Component'
import eventbus from 'gia/eventbus';
import stage from '../components/stage'
import renderer from '../components/render'

import _each from 'lodash.foreach'
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);



class Story extends Component {

  constructor(element) {

    super(element)

    this.element = element
    this.i = 0


    this.ref = {
      bg: null,
      mask: null,
      trigger: null,
      svg: null,
      bird: null,
      grid: null,
      screen:  []
    }

  }

  mount() {

    
        this.resizeQue = this.resize.bind(this)
        stage.on("stage:resize", this.resizeQue);

        this.resizeQue()


       gsap.fromTo(this.ref.grid, {

            opacity: 0
       }, {

          opacity: 1,

           scrollTrigger: {
                trigger: this.ref.trigger,
                start: "top top",
                end: "bottom top",
                scrub: true
           }
       })

       gsap.fromTo(this.ref.mask, {
            transformOrigin: "50% 50%",
           //  opacity: 0,
           scale: 2,
       }, {

           //  opacity: 1,
           transformOrigin: "50% 50%",
           scale: 1,

           scrollTrigger: {
               trigger: this.ref.trigger,
               start: "top top",
                 end: "bottom top",
               scrub: true
           }
       })



       const tl = gsap.timeline({
         scrollTrigger: {
           trigger: this.ref.trigger,
           start: "top bottom",
           end: "top top",
           scrub: .75,
           toggleActions: "play reverse play reverse"
         }
       });


       tl.to(this.ref.screen[0], {
         opacity: 1,
         duration: 0.1
       }, 0)

       tl.fromTo(this.ref.screen[0], {
          yPercent: 30
       },{
         yPercent: -20,
         duration: 0.5
       }, 0)

       tl.to(this.ref.screen[0], {
         opacity: 0,
         duration: 0.1
       }, 0.4)


       tl.to(this.ref.screen[1], {
         opacity: 1,
         duration: 0.1
       }, 0.5)

       tl.fromTo(this.ref.screen[1], {
         yPercent: 30
       }, {
         yPercent: -20,
         duration: 0.5
       }, 0.5)

       tl.to(this.ref.screen[1], {
         opacity: 0,
         duration: 0.1
       }, 0.9)


    }    



    resize(){


       const box = this.ref.bg.getBoundingClientRect()
       const offset =  stage.h - box.height
       this.ref.bg.style.setProperty('--bottom-offset', offset + "px");
       
       const svgoffset = stage.w < 960 ? stage.w / 2 : 480
       this.ref.grid.style.setProperty('--svg-height', svgoffset + "px");

    //    gsap.set(this.ref.mask, {
    //        transformOrigin: "50% 50%",
    //        x: "50vw",
    //        y: "50vh"
    //        // xPercent: -50,
    //        // yPercent: -50

    //    })

    }

    unmount() {



    }

}

module.exports = Story

//path to css polygon()
// let path = this.ref.path

// let pathLength = Math.floor(path.getTotalLength());
// let steps = 10;
// let scaled = Math.floor(pathLength / steps);
// let bbox = path.getBBox();

// let points = Object.keys([...new Array(scaled)]).map(num => {
//   let point = path.getPointAtLength(num * steps);
//   let x = (point.x / bbox.width * 100).toFixed(2);
//   let y = (point.y / bbox.height * 100).toFixed(2);
//   return `${x}% ${y}%`;
// }).join(',');


//console.log(pathLength);

//document.querySelector('style[title="s1"]').innerHTML = `.clip img
//{clip-path: polygon(${points});}`;


