import Component from 'gia/Component'
import _each from 'lodash.foreach'
import mapboxgl from 'mapbox-gl';


class Contact extends Component {

  constructor(element) {

    super(element)
    this.element = element

    this.index = 0


    this.ref = {
      clip: null,
      switch: null 
    }

  }

  mount() {


   

    this.getItems()

  


   
}

addMap(stores){
           
  
   mapboxgl.accessToken = 'pk.eyJ1IjoiYXNlcHRpYyIsImEiOiI0ZTh6OXRNIn0.0lGX9Uen_HFTZg9bYVJSlA';
   const map = new mapboxgl.Map({
     container: 'map', // container ID
     style: 'mapbox://styles/mapbox/light-v11', // style URL
     center: [21.88856398430876, 47.04899437763236], // starting position [lng, lat]
     zoom: 7, // starting zoom
     scrollZoom: false
   });
   const nav = new mapboxgl.NavigationControl();
   map.addControl(nav, 'top-left');
  
    /**
             * Wait until the map loads to make changes to the map.
             */
            map.on('load', () => {
              /**
               * This is where your '.addLayer()' used to be, instead
               * add only the source without styling a layer
               */
              map.addSource('places', {
                'type': 'geojson',
                'data': stores
              });

              /**
               * Add all the things to the page:
               * - The location listings on the side of the page
               * - The markers onto the map
               */
            //  buildLocationList(stores);
              addMarkers();
            });

            /**
             * Add a marker to the map for every store listing.
             **/
            function addMarkers() {
              /* For each feature in the GeoJSON object above: */
              for (const marker of stores.features) {
                console.log(marker);
                /* Create a div element for the marker. */
                const el = document.createElement('div');
                /* Assign a unique `id` to the marker. */
                el.id = `marker-${marker.properties.id}`;
                /* Assign the `marker` class to each marker for styling. */
                el.className = 'marker';

                /**
                 * Create a marker using the div element
                 * defined above and add it to the map.
                 **/
                new mapboxgl.Marker(el, {
                    offset: [0, -23]
                  })
                  .setLngLat(marker.geometry.coordinates)
                  .addTo(map);

                /**
                 * Listen to the element and when it is clicked, do three things:
                 * 1. Fly to the point
                 * 2. Close all other popups and display popup for clicked store
                 * 3. Highlight listing in sidebar (and remove highlight for all other listings)
                 **/
                el.addEventListener('click', (e) => {
                  /* Fly to the point */
                  flyToStore(marker);
                  /* Close all other popups and display popup for clicked store */
                  createPopUp(marker);
                  /* Highlight listing in sidebar */
                  const activeItem = document.getElementsByClassName('active');
                  e.stopPropagation();
                  if (activeItem[0]) {
                    activeItem[0].classList.remove('active');
                  }
                  const listing = document.getElementById(
                    `listing-${marker.properties.id}`
                  );
                  listing.classList.add('active');
                });
              }
            }

            /**
             * Add a listing for each store to the sidebar.
             **/
            // function buildLocationList(stores) {
            //   for (const store of stores.features) {
            //     /* Add a new listing section to the sidebar. */
            //     const listings = document.getElementById('listings');
            //     const listing = listings.appendChild(document.createElement('div'));
            //     /* Assign a unique `id` to the listing. */
            //     listing.id = `listing-${store.properties.id}`;
            //     /* Assign the `item` class to each listing for styling. */
            //     listing.className = 'listings__item listing';

            //     /* Add the link to the individual listing created above. */
            //     const link = listing.appendChild(document.createElement('a'));
            //     link.href = '#';
            //     link.className = 'listing__title';
            //     link.id = `link-${store.properties.id}`;
            //     link.innerHTML = `${store.properties.title}`;

            //     /* Add details to the individual listing. */
            //     const details = listing.appendChild(document.createElement('div'));
            //     details.className = 'listing__details'
            //     details.innerHTML = `<p>${store.properties.city} - ${store.properties.country}</p>`;
            //     if (store.properties.address) {
            //       details.innerHTML += `<p>${store.properties.address}</p>`;
            //     }

            //     /**
            //      * Listen to the element and when it is clicked, do four things:
            //      * 1. Update the `currentFeature` to the store associated with the clicked link
            //      * 2. Fly to the point
            //      * 3. Close all other popups and display popup for clicked store
            //      * 4. Highlight listing in sidebar (and remove highlight for all other listings)
            //      **/
            //     link.addEventListener('click', function (e) {
            //       e.preventDefault()
            //       for (const feature of stores.features) {
            //         if (this.id === `link-${feature.properties.id}`) {
            //           flyToStore(feature);
            //           createPopUp(feature);
            //         }
            //       }
            //       const activeItem = document.getElementsByClassName('active');
            //       if (activeItem[0]) {
            //         activeItem[0].classList.remove('active');
            //       }
            //       this.parentNode.classList.add('active');
            //     });
            //   }
            // }

            /**
             * Use Mapbox GL JS's `flyTo` to move the camera smoothly
             * a given center point.
             **/
            function flyToStore(currentFeature) {
              map.flyTo({
                center: currentFeature.geometry.coordinates,
                zoom: 12
              });
            }

            /**
             * Create a Mapbox GL JS `Popup`.
             **/
            function createPopUp(currentFeature) {
              const popUps = document.getElementsByClassName('mapboxgl-popup');
              if (popUps[0]) popUps[0].remove();
              const popup = new mapboxgl.Popup({
                  closeOnClick: false
                })
                .setLngLat(currentFeature.geometry.coordinates)
                .setHTML(
                  `<p><strong>${currentFeature.properties.title}</strong></p>
                  <p>${currentFeature.properties.city} - ${currentFeature.properties.country}</p>
                  <p>${currentFeature.properties.address}</p>
                  <p>${currentFeature.properties.phone}</p>
                  <p>${currentFeature.properties.email}</p>
                  <p>${currentFeature.properties.website}</p>
                  `
                )
                .addTo(map);
            }
}


    getItems() {

      fetch('/data/contact.json')
        .then((response) => response.json())
        .then((json) => {

          //   this.facestock = json[0].facestock;
          //   this.adhesive = json[0].adhesive;
          //   this.liner = json[0].liner;
          //   this.ink = json[0].ink;
          //   this.size = json[0].size;

          const features = [];

          json.forEach((item, i)=>{

            console.log([item.long, item.lang]);
            const obj = {
              'type': 'Feature',
              'geometry': {
                'type': 'Point',
                'coordinates': [item.lng, item.lat]
              },
              'properties': {
                'id': i,
                'title': item.title,
                'phone': item.phone,
                'address': item.street,
                'city': item.city,
                'country': item.country,
                'email': item.email,
                'website': item.website
              }
            }

            features[i] = obj
          })
          
          
          
          const jso = {
            'type': 'FeatureCollection',
            'features': features
          };

          
          this.addMap(jso)
          

        });
    }




  unmount() {




  }


}

module.exports = Contact
