import renderQue from './render';
import EventEmitter from 'events';

class StageModel extends EventEmitter {

    constructor(){

        super()
        this.w = 0
        this.f = 0
        this.doch = 0
        this.h = 0
        this.footer = 0
        this.halfh = 0
        this.halfw = 0
        this.scrollDirection = 0
        this.scrolling = !1
        this._tempScrollY = 0
        this._mediaQueries = null
        this.currentMQ = ""
        this.performanceTimer = 0
        this.orientationMode = ""
        this.enabled = 1

        window.addEventListener("resize", this.resize.bind(this), !1)
        window.addEventListener("orientationchange", this.resize.bind(this), !1)
        window.addEventListener("scroll", this.scroll.bind(this), !1)
        this.updateScrollY()
        this.resize()
        renderQue.add(this)

    }

    get scrolltop() {
         return this._windowScrollY;
    }

    set scrolltop(t) {
         this._windowScrollY = t;
    }

    renderQueCall(){
        return this.scrolling ? (this.scrolling = !1, this.updateScrollY()) : void 0
    }

    scroll(t) {
        return this.scrolling = !0
    }

    updateScrollY() {
      if (window.pageYOffset) return this._tempScrollY = window.pageYOffset, this.handleScroll()
      return document.documentElement.clientHeight ? this._tempScrollY = document.documentElement.scrollTop :  this._tempScrollY = document.body.scrollTop, this.handleScroll()
    }

    handleScroll() {

        this.scrollDirection = this._tempScrollY < this.scrolltop ? 1 : this._tempScrollY > this.scrolltop ? -1 : 0
        this.scrolltop = this._tempScrollY

        this.enabled && this.emit(StageModel.SCROLL, this)

    }

    resize(t) {

        return this.updateScrollY(),
        this.w = document.documentElement.clientWidth || window.innerWidth,
        this.h = document.documentElement.clientHeight || window.innerHeight,
        this.orientationMode = this.w / this.h <= .87 ? StageModel.ORIENTATION_PORTRAIT : StageModel.ORIENTATION_LANDSCAPE,
        this.emit(StageModel.RESIZE, this)

    }

}



StageModel.SCROLL = "stage:scroll"
StageModel.RESIZE = "stage:resize"
StageModel.RESPONSIVE_CHANGE = "stage:responsive"
StageModel.ORIENTATION_PORTRAIT = "portrait"
StageModel.ORIENTATION_LANDSCAPE = "landscape"

const instance = new StageModel()

module.exports = instance
