import Component from 'gia/Component'
import _each from 'lodash.foreach'
import gsap from 'gsap'

import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin.js";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin, MotionPathPlugin);


class Continents extends Component {

  constructor(element) {

    super(element)


    this.element = element
    this.index = 0


    this.ref = {
        text:  null,
        svg: null,
        line: [],
        circle: [],
        marker: []
    }

  }

  mount() {

    const tt = gsap.timeline({
        scrollTrigger: {
            trigger: this.ref.marker[0],
            start: "top 50%",
            end: "top top-=50%",
            scrub: .2,

            toggleActions: "play reverse play reverse"
        }
    });

    tt.fromTo(this.ref.text, {
        yPercent: 100
            }, {
        duration: 1,
        yPercent: -125,
        scale: .66
       
      }, 0);

    //   tt.to(this.ref.text,  {
    //       opacity: 0,
    //       yPercent: -100,
    //       duration: .5

    //   }, .5);

     tt.fromTo(this.ref.svg, {
         opacity: 0,
     }, {
         duration: .1,
         opacity: 1
     }, 0.6);



    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: this.ref.marker[1],
            start: "top 80%",
            end: "bottom bottom",
            scrub: .2,

            toggleActions: "play reverse play reverse"
        }
    });

   

    this.ref.line.forEach((line, index)=>{

        gsap.set(line, {
            drawSVG: '100% 100%'
        })

        tl.to(line, {
            duration: .3,
            drawSVG: '0 100%'
        }, 0.04 * index);

        tl.from(this.ref.circle[index], {
            motionPath: {
                path: line,
                align: line,
                alignOrigin: [0.5, 0.5]
            },
            duration: .3
        }, 0.04 * index);
    })


    

   
   
    //tl.to(item, { opacity: 0, yPercent: -50, duration: 0.2 }, 0.8)

   

    
}






  unmount() {




  }


}

module.exports = Continents
