import Component from 'gia/Component'
import render from '../components/render'
import stage from '../components/stage'
import _each from 'lodash.foreach'

class Popup extends Component {

  constructor(element) {

    super(element)

    this.body = $('body')
    this.element = element
    this.el = $(element)

    this.ref = {
      popup: []
    }

  }


  mount() {

    
    _each(this.ref.popup, (e) => {
      $(e).on('click', (evt) => {
        evt.preventDefault()
        $('.popup').addClass('is-active')


         const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
         const body = document.body;
         body.style.position = 'fixed';
         body.style.top = `-${scrollY}`;


      })
    })

    $('.popup__close, .popup__bg ').on('click', (e) => {
      e.preventDefault()
      $('.popup').removeClass('is-active')

       const body = document.body;
       const scrollY = body.style.top;
       body.style.position = '';
       body.style.top = '';
       window.scrollTo(0, parseInt(scrollY || '0') * -1);
    })

    window.addEventListener('scroll', () => {
      document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    });

   

   


  }

  unmount() {
    stage.removeAllListeners();
  }


  resize(e){

    
    this.render(e)

  }


}



module.exports = Popup
