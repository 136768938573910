import Component from 'gia/Component'
import _each from 'lodash.foreach'
import mapboxgl from 'mapbox-gl';


class Locations extends Component {

  constructor(element) {

    super(element)
    this.element = element

    this.index = 0


    this.ref = {
      clip: null,
      switch: null,
      rep: null,
      showroom: null  
    }

  }

  mount() {


    this.ref.rep.addEventListener('click', (e)=>{

      e.preventDefault()
      $('.but-active').removeClass('but-active')
      this.redoMap("Commercial Representative")
      this.ref.rep.classList.add('but-active')
    
    })

    this.ref.showroom.addEventListener('click', (e) => {

      e.preventDefault()
      $('.but-active').removeClass('but-active')
      this.redoMap("Partner Showroom")
      this.ref.showroom.classList.add('but-active')

    })
   

    this.getItems("Commercial Representative")

    //  const stores = {
    //    'type': 'FeatureCollection',
    //    'features': [{
    //        'type': 'Feature',
    //        'geometry': {
    //          'type': 'Point',
    //          'coordinates': [-77.034084142948, 38.909671288923]
    //        },
    //        'properties': {
    //          'phoneFormatted': '(202) 234-7336',
    //          'phone': '2022347336',
    //          'address': '1471 P St NW',
    //          'city': 'Washington DC',
    //          'country': 'United States',
    //          'crossStreet': 'at 15th St NW',
    //          'postalCode': '20005',
    //          'state': 'D.C.'
    //        }
    //      },
    //      {
    //        'type': 'Feature',
    //        'geometry': {
    //          'type': 'Point',
    //          'coordinates': [-77.049766, 38.900772]
    //        },
    //        'properties': {
    //          'phoneFormatted': '(202) 507-8357',
    //          'phone': '2025078357',
    //          'address': '2221 I St NW',
    //          'city': 'Washington DC',
    //          'country': 'United States',
    //          'crossStreet': 'at 22nd St NW',
    //          'postalCode': '20037',
    //          'state': 'D.C.'
    //        }
    //      },
    //      {
    //        'type': 'Feature',
    //        'geometry': {
    //          'type': 'Point',
    //          'coordinates': [-77.043929, 38.910525]
    //        },
    //        'properties': {
    //          'phoneFormatted': '(202) 387-9338',
    //          'phone': '2023879338',
    //          'address': '1512 Connecticut Ave NW',
    //          'city': 'Washington DC',
    //          'country': 'United States',
    //          'crossStreet': 'at Dupont Circle',
    //          'postalCode': '20036',
    //          'state': 'D.C.'
    //        }
    //      },
    //      {
    //        'type': 'Feature',
    //        'geometry': {
    //          'type': 'Point',
    //          'coordinates': [-77.0672, 38.90516896]
    //        },
    //        'properties': {
    //          'phoneFormatted': '(202) 337-9338',
    //          'phone': '2023379338',
    //          'address': '3333 M St NW',
    //          'city': 'Washington DC',
    //          'country': 'United States',
    //          'crossStreet': 'at 34th St NW',
    //          'postalCode': '20007',
    //          'state': 'D.C.'
    //        }
    //      },
    //      {
    //        'type': 'Feature',
    //        'geometry': {
    //          'type': 'Point',
    //          'coordinates': [-77.002583742142, 38.887041080933]
    //        },
    //        'properties': {
    //          'phoneFormatted': '(202) 547-9338',
    //          'phone': '2025479338',
    //          'address': '221 Pennsylvania Ave SE',
    //          'city': 'Washington DC',
    //          'country': 'United States',
    //          'crossStreet': 'btwn 2nd & 3rd Sts. SE',
    //          'postalCode': '20003',
    //          'state': 'D.C.'
    //        }
    //      },
    //      {
    //        'type': 'Feature',
    //        'geometry': {
    //          'type': 'Point',
    //          'coordinates': [-76.933492720127, 38.99225245786]
    //        },
    //        'properties': {
    //          'address': '8204 Baltimore Ave',
    //          'city': 'College Park',
    //          'country': 'United States',
    //          'postalCode': '20740',
    //          'state': 'MD'
    //        }
    //      },
    //      {
    //        'type': 'Feature',
    //        'geometry': {
    //          'type': 'Point',
    //          'coordinates': [-77.097083330154, 38.980979]
    //        },
    //        'properties': {
    //          'phoneFormatted': '(301) 654-7336',
    //          'phone': '3016547336',
    //          'address': '4831 Bethesda Ave',
    //          'cc': 'US',
    //          'city': 'Bethesda',
    //          'country': 'United States',
    //          'postalCode': '20814',
    //          'state': 'MD'
    //        }
    //      },
    //      {
    //        'type': 'Feature',
    //        'geometry': {
    //          'type': 'Point',
    //          'coordinates': [-77.359425054188, 38.958058116661]
    //        },
    //        'properties': {
    //          'phoneFormatted': '(571) 203-0082',
    //          'phone': '5712030082',
    //          'address': '11935 Democracy Dr',
    //          'city': 'Reston',
    //          'country': 'United States',
    //          'crossStreet': 'btw Explorer & Library',
    //          'postalCode': '20190',
    //          'state': 'VA'
    //        }
    //      },
    //      {
    //        'type': 'Feature',
    //        'geometry': {
    //          'type': 'Point',
    //          'coordinates': [-77.10853099823, 38.880100922392]
    //        },
    //        'properties': {
    //          'phoneFormatted': '(703) 522-2016',
    //          'phone': '7035222016',
    //          'address': '4075 Wilson Blvd',
    //          'city': 'Arlington',
    //          'country': 'United States',
    //          'crossStreet': 'at N Randolph St.',
    //          'postalCode': '22203',
    //          'state': 'VA'
    //        }
    //      },
    //      {
    //        'type': 'Feature',
    //        'geometry': {
    //          'type': 'Point',
    //          'coordinates': [-75.28784, 40.008008]
    //        },
    //        'properties': {
    //          'phoneFormatted': '(610) 642-9400',
    //          'phone': '6106429400',
    //          'address': '68 Coulter Ave',
    //          'city': 'Ardmore',
    //          'country': 'United States',
    //          'postalCode': '19003',
    //          'state': 'PA'
    //        }
    //      },
    //      {
    //        'type': 'Feature',
    //        'geometry': {
    //          'type': 'Point',
    //          'coordinates': [-75.20121216774, 39.954030175164]
    //        },
    //        'properties': {
    //          'phoneFormatted': '(215) 386-1365',
    //          'phone': '2153861365',
    //          'address': '3925 Walnut St',
    //          'city': 'Philadelphia',
    //          'country': 'United States',
    //          'postalCode': '19104',
    //          'state': 'PA'
    //        }
    //      },
    //      {
    //        'type': 'Feature',
    //        'geometry': {
    //          'type': 'Point',
    //          'coordinates': [-77.043959498405, 38.903883387232]
    //        },
    //        'properties': {
    //          'phoneFormatted': '(202) 331-3355',
    //          'phone': '2023313355',
    //          'address': '1901 L St. NW',
    //          'city': 'Washington DC',
    //          'country': 'United States',
    //          'crossStreet': 'at 19th St',
    //          'postalCode': '20036',
    //          'state': 'D.C.'
    //        }
    //      }
    //    ]
    //  };


   
}

redoMap(cat){
  this.listings.innerHTML = ""
  this.map.remove()
  this.getItems(cat)
}

addMap(stores){
           
  
   mapboxgl.accessToken = 'pk.eyJ1IjoiYXNlcHRpYyIsImEiOiI0ZTh6OXRNIn0.0lGX9Uen_HFTZg9bYVJSlA';
   this.map = new mapboxgl.Map({
     container: 'map', // container ID
     style: 'mapbox://styles/mapbox/light-v11', // style URL
     center: [21.88856398430876, 47.04899437763236], // starting position [lng, lat]
     zoom: 10, // starting zoom
     scrollZoom: false
   });
   const nav = new mapboxgl.NavigationControl();
   this.map.addControl(nav, 'top-left');

   this.map.addControl(
     new mapboxgl.GeolocateControl({
       positionOptions: {
         enableHighAccuracy: true
       },
       // When active the map will receive updates to the device's location as it changes.
       trackUserLocation: true,
       // Draw an arrow next to the location dot to indicate which direction the device is heading.
       showUserHeading: true
     })
   );
  
    /**
             * Wait until the map loads to make changes to the map.
             */
            this.map.on('load', () => {
              /**
               * This is where your '.addLayer()' used to be, instead
               * add only the source without styling a layer
               */
               this.map.addSource('places', {
                'type': 'geojson',
                'data': stores
              });

              /**
               * Add all the things to the page:
               * - The location listings on the side of the page
               * - The markers onto the map
               */
              buildLocationList(stores);
              addMarkers();
            });

            const thiz = this

            /**
             * Add a marker to the map for every store listing.
             **/
            function addMarkers() {

              /* For each feature in the GeoJSON object above: */
              for (const marker of stores.features) {
                /* Create a div element for the marker. */
                const el = document.createElement('div');
                /* Assign a unique `id` to the marker. */
                el.id = `marker-${marker.properties.id}`;
                /* Assign the `marker` class to each marker for styling. */
                el.className = 'marker';

                /**
                 * Create a marker using the div element
                 * defined above and add it to the map.
                 **/
                new mapboxgl.Marker(el, {
                    offset: [0, -23]
                  })
                  .setLngLat(marker.geometry.coordinates)
                  .addTo(thiz.map);

                /**
                 * Listen to the element and when it is clicked, do three things:
                 * 1. Fly to the point
                 * 2. Close all other popups and display popup for clicked store
                 * 3. Highlight listing in sidebar (and remove highlight for all other listings)
                 **/
                el.addEventListener('click', (e) => {
                  /* Fly to the point */
                  flyToStore(marker);
                  /* Close all other popups and display popup for clicked store */
                  createPopUp(marker);
                  /* Highlight listing in sidebar */
                  const activeItem = document.getElementsByClassName('active');
                  e.stopPropagation();
                  if (activeItem[0]) {
                    activeItem[0].classList.remove('active');
                  }
                  const listing = document.getElementById(
                    `listing-${marker.properties.id}`
                  );
                  listing.classList.add('active');
                });
              }
            }

            /**
             * Add a listing for each store to the sidebar.
             **/
            function buildLocationList(stores) {
              for (const store of stores.features) {
                /* Add a new listing section to the sidebar. */
                thiz.listings = document.getElementById('listings');
                const listings = thiz.listings
                const listing = listings.appendChild(document.createElement('div'));
                /* Assign a unique `id` to the listing. */
                listing.id = `listing-${store.properties.id}`;
                /* Assign the `item` class to each listing for styling. */
                listing.className = 'listings__item listing';

                /* Add the link to the individual listing created above. */
                const link = listing.appendChild(document.createElement('a'));
                link.href = '#';
                link.className = 'listing__title';
                link.id = `link-${store.properties.id}`;
                link.innerHTML = `${store.properties.city} - ${store.properties.country}`;

                /* Add details to the individual listing. */
                const details = listing.appendChild(document.createElement('div'));
                details.className = 'listing__details'
                details.innerHTML = `<p>${store.properties.title}</p>`;
                // if (store.properties.address) {
                //   details.innerHTML += `<p>${store.properties.address}</p>`;
                // }

                /**
                 * Listen to the element and when it is clicked, do four things:
                 * 1. Update the `currentFeature` to the store associated with the clicked link
                 * 2. Fly to the point
                 * 3. Close all other popups and display popup for clicked store
                 * 4. Highlight listing in sidebar (and remove highlight for all other listings)
                 **/
                link.addEventListener('click', function (e) {
                  e.preventDefault()
                  for (const feature of stores.features) {
                    if (this.id === `link-${feature.properties.id}`) {
                      flyToStore(feature);
                      createPopUp(feature);
                    }
                  }
                  const activeItem = document.getElementsByClassName('active');
                  if (activeItem[0]) {
                    activeItem[0].classList.remove('active');
                  }
                  this.parentNode.classList.add('active');
                });
              }
            }

            /**
             * Use Mapbox GL JS's `flyTo` to move the camera smoothly
             * a given center point.
             **/
            function flyToStore(currentFeature) {
               thiz.map.flyTo({
                center: currentFeature.geometry.coordinates,
                zoom: 15
              });
            }

            /**
             * Create a Mapbox GL JS `Popup`.
             **/
            function createPopUp(currentFeature) {
              const popUps = document.getElementsByClassName('mapboxgl-popup');
              if (popUps[0]) popUps[0].remove();
              const popup = new mapboxgl.Popup({
                  closeOnClick: false
                })
                .setLngLat(currentFeature.geometry.coordinates)
                .setHTML(
                  `<p><strong>${currentFeature.properties.title}</strong></p>
                  <p>${currentFeature.properties.city} - ${currentFeature.properties.country}</p>
                  <p>${currentFeature.properties.address ? currentFeature.properties.address : ''}</p>
                  <p>${currentFeature.properties.phone}</p>
                  <p>${currentFeature.properties.email}</p>
  
                  `
                )
                .addTo(thiz.map);
            }
}


    getItems(cat) {

      fetch('/data/locs.json')
        .then((response) => response.json())
        .then((json) => {


          const features = [];

          json.filter(item => item.category == cat || item.category == "Headquarter").forEach((item, i) => {

           // console.log([item.long, item.lang]);
            const obj = {
              'type': 'Feature',
              'geometry': {
                'type': 'Point',
                'coordinates': [item.long, item.lat]
              },
              'properties': {
                'id': i,
                'title': item.name,
                'phone': item.phone,
                'address': item.address,
                'city': item.city,
                'country': item.country,
                'email': item.email
              }
            }

            features[i] = obj
          })
          
          
          
          const jso = {
            'type': 'FeatureCollection',
            'features': features
          };

          
          this.addMap(jso)
          

        });
    }




  unmount() {




  }


}

module.exports = Locations
