'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = createInstance;

var _config = require('./config');

var _config2 = _interopRequireDefault(_config);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Creates and returns instance of component
 * @param element: DOM element
 * @param componentName: Component name
 * @param component: Component constructor
 * @param options: options object passed into a component
 */

function createInstance(element, componentName, component, options) {
    component.prototype._name = componentName;
    var instance = new component(element, options);

    if (_config2.default.get('log')) {
        console.info('Created instance of component "' + componentName + '".');
    }
    return instance;
}
