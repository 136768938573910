import Component from 'gia/Component'
import _each from 'lodash.foreach'
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);


class Form extends Component {

  constructor(element) {

    super(element)

    this.element = element
    this.index = 0


    this.ref = {
        show: null,
        form: null,
        container: null,
        role: null
    }

  }

    mount() {

       this.ref.show && this.ref.show.addEventListener('click', (e) => {
            e.preventDefault()

            this.element.classList.add('form-selected')
            this.ref.role.classList.add('form-selected')
            
        })

         this.ref.form.addEventListener("submit", this.handleSubmit.bind(this));
        
    }

    handleSubmit(event) {
      event.preventDefault();

      const myForm = this.ref.form;
      const formData = new FormData(myForm);

      this.ref.container.classList.add('is-sending')

      fetch("/", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: new URLSearchParams(formData).toString(),
        })
        .then((e) => this.formsent(e))
        .catch((error) => this.error(error));
    };

    formsent(e) {
      if (e.ok) {
        this.ref.form.reset();
        this.ref.container.classList.remove('is-sending')
        this.ref.container.classList.add('is-sent')
      } else {
        this.error('Email failed to send')

      }

    }

    error(error) {
      this.ref.container.classList.remove('is-sending')
      this.ref.container.classList.add('is-error')
    }


    unmount() {

    }


}

module.exports = Form