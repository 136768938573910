import Component from 'gia/Component'
import _each from 'lodash.foreach'
import stage from '../components/stage'


class Menu extends Component {

  constructor(element) {

    super(element)

    this.element = element
    this.index = 0
    this.body = document.body

    this.ref = {
      dropdown: null, 
      drop: null,
      hamburger: null, 
      category: [],
      list: [],
      row: []
    }

  }

  mount() {

  //  lenis.on('scroll', this.scroll.bind(this) )
  this.ishome = this.element.dataset.home == "true"
  this.resizeQue = this.resize.bind(this)
  stage.on("stage:resize", this.resizeQue);
  this.renderQue = this.render.bind(this)
  stage.on("stage:scroll", this.renderQue);
  this.resize(stage)

  this.renderQue(stage)



  const mediaQueryList = window.matchMedia("(max-width: 640px)");
  this.handleOrientationChange(mediaQueryList);
  mediaQueryList.addEventListener("change", this.handleOrientationChange);

  //console.log(this.isMobile, "this.isMobile");

     
     this.ref.dropdown.addEventListener('mouseenter', (e) => {

      if(!this.isMobile) {
        this.element.classList.add('has-dropdown')
        document.body.classList.add('has-dropdown')
      }
        
     })

     this.ref.dropdown.addEventListener('mouseleave', (e) => {
       if(!this.isMobile){
          this.element.classList.remove('has-dropdown')
          document.body.classList.remove('has-dropdown')
       }
        
     })


     this.ref.hamburger.addEventListener('click', (e) => {
       //this.element.classList.add('has-dropdown')
       document.body.classList.toggle('has-mobile-menu')
       $('.has-list').removeClass('has-list')
       this.ref.dropdown.classList.remove('has-products')
     })

     this.ref.drop.addEventListener('click', (e) => {
       if (this.isMobile) {
          $('.has-list').removeClass('has-list')
          this.ref.dropdown.classList.toggle('has-products')
       }

     })

     this.ref.category.forEach((category, index) => {
      if (this.isMobile) {
        category.addEventListener('click', (e) => {
          e.preventDefault()
          // if (this.ref.row[index].classList.contains('has-list')){
          //   this.ref.row[index].classList.remove('has-list')
          // }
          // else{
          //  // $('.has-list').removeClass('has-list')
          //   this.ref.row[index].classList.add('has-list')
          // }

          this.ref.row[index].classList.toggle('has-list')
          
          
        })
      }
     });

     




    
}

handleOrientationChange(mql) {
  this.isMobile = mql.matches
}

resize(e) {

  const box = this.element.getBoundingClientRect()
  this.h = box.height

  //console.log(this.h);
}

render(e) {

  //console.log(e);

  if (e.scrollDirection < 0) {
    if(this.ishome){
      if (e.scrolltop > (.556*stage.w - this.h)) {
        this.body.classList.add('hide-menu')
        this.body.classList.remove('bl-menu')
      }
      else{
        this.body.classList.add('bl-menu')
      }
    }
    else {
      if (e.scrolltop > stage.h / 10) {
        this.body.classList.add('hide-menu')
      }
    }
    

  }
  else {
    
       this.body.classList.remove('hide-menu')

        if (this.ishome) {
          if (e.scrolltop < (.556 * stage.w - this.h)) {
            this.body.classList.add('bl-menu')
          }
          else {
            this.body.classList.remove('bl-menu')
          }
        }
  
     
  }
}


  unmount() {




  }


}

module.exports = Menu
