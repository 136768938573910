import Component from 'gia/Component'
import _each from 'lodash.foreach'
import gsap from 'gsap'

import Observer from '../components/observer'

class Partner extends Component {

  constructor(element) {

    super(element)

    this.element = element

    this.index = 0


    this.ref = {
      overlay: null
    }

  }

  mount() {


    gsap.delayedCall(1, ()=> {
      this.ref.overlay.classList.add('visible')
    })


    
}






  unmount() {




  }


}

module.exports = Partner
