import Component from 'gia/Component'
import eventbus from 'gia/eventbus';
import stage from '../components/stage'
import renderer from '../components/render'

import _each from 'lodash.foreach'
import gsap from 'gsap'
import {
  ScrollTrigger
} from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);



class Discover extends Component {

  constructor(element) {

    super(element)

    this.body = $('body')
    this.html = $('html')
    this.menu = $('.menu')
    this.element = element
    this.el = $(element)
    this.i = 0


    this.ref = {
      square: null,
      bird: null,
      group: null,
      mask: null,
      svg:  null,
      trigger: null,
      overlay: null
    }

  }

  mount() {


    this.resizeQue = this.resize.bind(this)
    stage.on("stage:resize", this.resizeQue);

    this.resizeQue()
    // const rect = this.element.getBoundingClientRect();
    // this.scale = rect.height / 93

    // console.log(this.scale);

    //238x88
    gsap.to(this.ref.mask, {
      transformOrigin: "50% 50%",
      scale: 2,

      scrollTrigger: {
        trigger: this.element,
        start: "top 20%",
        end: "bottom bottom+=100%",
        scrub: 0.2
      }
    })
    

    gsap.to(this.ref.svg, {
      
      opacity: 0,

      scrollTrigger: {
        trigger: this.element,
        start: "top top",
        end: "bottom bottom+=100%",
        scrub: 0.2
      }
    })

     gsap.fromTo(this.ref.overlay, {
       opacity: 0,
        yPercent: 30
     },
      {
      opacity: 1,
       yPercent: -10,
       scrollTrigger: {
         trigger: this.ref.trigger,
         start: "top bottom",
         end: "top top",
         scrub: 0.2
       }
     })

    // //245x246
    // gsap.to(this.ref.square, {
    //   transformOrigin: "50% 50%",
    //   scale: .2,

    //   scrollTrigger: {
    //     trigger: this.element,
    //     start: "top 20%",
    //     end: "top top-=10%",
    //     scrub: 0.2,
    //     invalidateOnRefresh: true
    //   }
    // })



  }



  resize() {


    gsap.set(this.ref.mask, {
      transformOrigin: "50% 50%",
      x: "50vw",
      y: "50vh",
      //xPercent: -50,
      // yPercent: -50

    })


  }

  unmount() {



  }

}

module.exports = Discover

//path to css polygon()
// let path = this.ref.path

// let pathLength = Math.floor(path.getTotalLength());
// let steps = 10;
// let scaled = Math.floor(pathLength / steps);
// let bbox = path.getBBox();

// let points = Object.keys([...new Array(scaled)]).map(num => {
//   let point = path.getPointAtLength(num * steps);
//   let x = (point.x / bbox.width * 100).toFixed(2);
//   let y = (point.y / bbox.height * 100).toFixed(2);
//   return `${x}% ${y}%`;
// }).join(',');


//console.log(pathLength);

//document.querySelector('style[title="s1"]').innerHTML = `.clip img
//{clip-path: polygon(${points});}`;
