import Component from 'gia/Component'
import _each from 'lodash.foreach'
import gsap from 'gsap'

import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);


class Continents extends Component {

  constructor(element) {

    super(element)


    this.element = element
    this.index = 0


    this.ref = {
        //path:  null,
        card: [],
        marker: null,
        path: null
    }

  }

  mount() {

   

    const tt = gsap.to(this.ref.path , {
        xPercent: 100,
        ease: 'none',
        scrollTrigger: {
            trigger: this.ref.marker,
            start: "top 50%",
            end: "bottom bottom",
            scrub: .2
        }
    });

   

    this.ref.card.forEach((card, index)=>{



        const tl = gsap.timeline({
          scrollTrigger: {
             trigger: card,
               containerAnimation: tt,
               start: "right 20%",
               end: "left 80%",
               scrub: .2
          }
        });

        tl.fromTo(card, {
          yPercent: 50, 
          opacity: 0
        },{
          opacity: 1,
          yPercent: 0,
          duration: 0.2
        }, 0)
        tl.to(card, {
          opacity: 0,
          yPercent: 50,
          duration: 0.2
        }, 0.8)




    })


    

   
   
    //tl.to(item, { opacity: 0, yPercent: -50, duration: 0.2 }, 0.8)

   

    
}






  unmount() {




  }


}

module.exports = Continents
