import Component from 'gia/Component'
import render from '../components/render'
import stage from '../components/stage'
import _each from 'lodash.foreach'

class Vid extends Component {

  constructor(element) {

    super(element)

    this.body = $('body')
    this.element = element
    this.el = $(element)

    this.ref = {
      video: []
    }

  }


  mount() {

    var thiz = this

   

    _each(this.ref.video, (e) => {
      $(e).on('click', (evt) => {
        evt.preventDefault()
        $('.player').addClass('is-active')
        var video = templateyoutube(e.dataset.video)
        $('.player__container').empty().append(video)
      })
    })

    $('.player__close, .player__bg ').on('click', (e) => {
      $('.player').removeClass('is-active')
      $('.player__container').empty()
    })

    // $(this.ref.player).on('click', (evt) => {
    //   evt.preventDefault()
    //   $('.player').addClass('is-active')
    //   var video = templateyoutube(this.ref.player.dataset.video)
    //   $(this.ref.player).empty().append(video)
    // })

   


  }

  unmount() {
    stage.removeAllListeners();
  }


  resize(e){

    
    this.render(e)

  }


}

let template = (video)=> `<iframe src="https://player.vimeo.com/video/${video}?autoplay=1&amp;app_id=122963" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen=""></iframe>`
let templateyoutube = (video) => `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/${video}?rel=0&autoplay=1" frameborder="0" allow="autoplay; encrypted-media; gyroscope" allowfullscreen></iframe>`

module.exports = Vid
