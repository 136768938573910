import Component from 'gia/Component'
import eventbus from 'gia/eventbus';
import stage from '../components/stage'
import renderer from '../components/render'

import _each from 'lodash.foreach'
import gsap from 'gsap'
import { Draggable } from "gsap/Draggable.js";
import { InertiaPlugin } from "gsap/InertiaPlugin.js";
gsap.registerPlugin(Draggable, InertiaPlugin)
import Sequencer from './sequencer'

import Flickity from 'flickity'



class Animation extends Component {

  constructor(element) {

    super(element)

    this.body = $('body')
    this.html = $('html')
    this.menu = $('.menu')
    this.element = element
    this.el = $(element)
    this.i = 0


    this.ref = {
      canvas: null,
      el: null,
      proxy: null,
      nav: null, 
      bar: null
    }

  }

  mount() {

   // console.log(this.ref.el.innerWidth);
    const first= this.element.dataset.first
    const last = this.element.dataset.last

    this.s2 = Sequencer.make({
         canvas: this.ref.canvas,
         from: first,
         to: last,
         scaleMode: "contain",
         playMode: "gsap",
         el: this.ref.el,
         proxy: this.ref.proxy,
         imageLoad : this.imageLoad.bind(this), 
         queueComplete: this.queueComplete.bind(this)
        
    })

    this.s2.paused = true
    this.s2.runloop()

     this.element.addEventListener("touchstart", (e) => {
       this.element.classList.add('slider-card--drag')
     });

    // this.ref.nav.addEventListener("mouseup", (e) => {
    //   this.s2.paused = true
    // });

    this.resizeQue = this.resize.bind(this)
    stage.on("stage:resize", this.resizeQue);


  }

  imageLoad(e){
    

    gsap.set(this.ref.bar, {
      transformOrigin: 'left',
      scaleX: e.count/e.total
    })
  }

   queueComplete(e) {
      this.element.classList.add('slider-card--loaded')
   }


  resize(){

    this.s2.size(this.ref.el.clientWidth, this.ref.el.clientHeight);
  }

  unmount() {




  }


}

module.exports = Animation
