import Component from 'gia/Component'
import _each from 'lodash.foreach'
import Observer from '../components/observer'


class Page extends Component {

  constructor(element) {

    super(element)

    this.element = element
 


    this.ref = {
      video: []
    }

  }

  mount() {


        this.observer = new Observer({
            rootMargin: '-10%',
            callback: (visible, target, entry) => {
                if (visible) {
                    target && target.play()
                } else {
                    target && target.pause()
                }
            }
        });

        this.observer.add('video');


      const invobserver = new Observer({
        rootMargin: '100% 0% 0% 0%'
      });

      invobserver.add('.invanim');

}






  unmount() {




  }


}

module.exports = Page
