const Classy = element => {
  const el =
    typeof element === "string"
      ? Array.prototype.slice.call(document.querySelectorAll(element), 0)
      : Array.isArray(element)
      ? element
      : [element]

  const forEach = function(array, callback) {
    for (let i = 0; i < array.length; i++) {
      callback(array[i], i)
    }
  }

  function addClass(el, className) {
    if (el) {
      if (el.classList) el.classList.add(className)
      else el.className += " " + className
    }
  }

  function removeClass(el, className) {
    if (el) {
      if (el.classList) el.classList.remove(className)
      else
        el.className = el.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        )
    }
  }

  function toggleClass(el, className) {
    if (el) {
      if (el.classList) el.classList.toggle(className)
      else {
        const classes = el.className.split(" ")
        const existingIndex = classes.indexOf(className)
        if (existingIndex >= 0) classes.splice(existingIndex, 1)
        else classes.push(className)
        el.className = classes.join(" ")
      }
    }
  }

  function forEachAndEach(el, className, method) {
    const classNames = Array.isArray(className) ? className : [className]
    forEach(el, e => {
      forEach(classNames, c => {
        method(e, c)
      })
    })
  }

  const add = className => {
    forEachAndEach(el, className, addClass)
    return methods
  }

  const remove = className => {
    forEachAndEach(el, className, removeClass)
    return methods
  }

  const toggle = className => {
    forEachAndEach(el, className, toggleClass)
    return methods
  }

  const has = className => el[0]?.classList.contains(className)

  const methods = { add, remove, toggle, has }

  return methods
}

export default Classy
