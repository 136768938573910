import Component from 'gia/Component'
import _each from 'lodash.foreach'
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);


class Cover extends Component {

  constructor(element) {

    super(element)

 
    this.element = element
    this.body = document.body
    this.index = 0


    this.ref = {
     logo: null,
     wrap: null,
     trigger: null,
     container: null,
     image: null,
     content: null
    }

  }

  mount() {

    
     const tl = gsap.timeline({
       scrollTrigger: {
         trigger: this.ref.trigger,
           start: "top top",
           end: "bottom top",
           scrub: .2,
          toggleActions: "play reverse play reverse",
          onLeave: () => this.body.classList.remove('menu-nologo'),
          onEnterBack: () => this.body.classList.add('menu-nologo')
       }
     });

     tl.to(this.ref.logo, {
       scale: .5882,
       duration: 1,
      // ease: "power2.inOut"
     }, 0)
     tl.to(this.ref.wrap, {
      yPercent: -100,
       duration: 1,
      // ease: "power2.inOut",
     }, 0)


     gsap.to(
       this.ref.image, {
         scrollTrigger: {
           trigger: this.element,
           start: "top top",
           end: "bottom top",
           scrub: .2,
           onLeave:  ()=>{
            console.log('leave');
            this.element.classList.add('not-visible')
           },
           onEnterBack: () => {
             console.log('back');
             this.element.classList.remove('not-visible')
           }
         },
         ease: 'none',
         yPercent: -30
       }
     )


     gsap.to(
       this.ref.content, {
         scrollTrigger: {
           trigger: this.element,
           start: "top top",
           end: "bottom top",
           scrub: .2
         },
         ease: 'none',
         yPercent: -50
       }
     )
    

    
}






  unmount() {




  }


}

module.exports = Cover
